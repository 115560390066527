import hillo from 'hillo'
import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import Servant from '@/model/dataLayer/api/tableAndPerson/Servant'
import i18n from '@/i18n'

const entity = {
  eventName: {},
  logLevel: {},
  orderId: {},
  tableId: {},
  opBrief: {},
  servantId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: async () => await Servant.getList(true),
        itemText: 'name',
        itemValue: 'id'
      }
    },
    displayName: i18n.t('staff')
  }
}

const config = {
  load: async function (filter) {
    const [fromDateTime, toDateTime] = filter[0].dateFilter
    return (await hillo.post('Complex.php?op=getDishesOrdersFullLogByTime', {
      fromDateTime,
      toDateTime
    })).content
  }
}
export default IKDataEntity.ModelFactory(entity, config)

export async function getOrderDetailLogById (id) {
  return (await hillo.post('Complex.php?op=getDishesOrdersFullLogOrderId', {
    orderId: id
  })).content
}

export function formatLogDish (dish) {
  if (dish.displayApply) {
    dish.modName = dish.displayApply.map(it => it.value).join(',')
  }
  return dish
}
